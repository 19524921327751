@import "bootstrap-sass/bootstrap/variables";
$screen: 750;
$screen_pad: $screen-md;
$screen_pc: 1920;
@function rem($px) {
    @return round(($px/$screen*10)*100)/100+rem;
}

@function rempad($px) {
  @return round(($px/$screen_pad*10)*100)/100+rem;
}

@function rempc($px) {
  @return round(($px/$screen_pc*10)*100)/100+rem;
}

@mixin icon-line(){
  padding-left: 18px;position: relative;
  &:before{content: '';position: absolute;width: 12px;height: 1px;background-color: $brand-danger;left: 0;top: 50%;}
}

@mixin title-arrow-down($bottom, $height, $isCenter:false){
  position: relative;padding-bottom: $bottom;
  &:before{
    content: '';position: absolute;bottom: 0;left: 0;height: $height;background-color: $gray-light;width: 50px;
    @if $isCenter{
      left: 50%;margin-left: -25px;
    }
  }
}
@mixin title-arrow-top($top, $height, $isCenter:false){
  position: relative;padding-top: $top;
  &:before{
    content: '';position: absolute;top: 0;left: 0;height: $height;background-color: $gray-light;width: 50px;
    @if $isCenter{
      left: 50%;margin-left: -25px;
    }
  }
}

@mixin scrollbar(){
  /*滚动条样式*/
  &::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: $brand-primary;
  }
  &::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: $gray-darker;
  }
  -webkit-overflow-scrolling: touch;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin indent-top($bgcolor: $body-bg){
  background: $bgcolor;position: relative;width: 88%;margin-top: -116px;padding: 3% 10%;border-top: 4px solid #D2B88A;

  @media (max-width: $screen-md-max) {
    margin-top: 0;
  }
  @media (max-width: $screen-xs-max) {
    padding: 6% 5%;
  }
}