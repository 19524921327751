@import "../config";

$nav-height: 60px;

nav {
  height: $nav-height;
}
.navbar {
  height: $nav-height;
  line-height: $nav-height;
  background-color: $gray-base;
  @include clearfix;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;

  .logo {
    position: absolute;
    height: inherit;
    left: 50%;
    margin-left: -21px;
    width: 58px;

    @media(min-width: $screen-md) {
      position: static;
      display: inline-block;
      margin-left: 10%;
      margin-right: 5%;
    }


    img {
      margin-top: -5%;
    }
  }

  .handle {
    position: absolute;
    right: 2%;
    top: 0;
    a {
      float: left;
      line-height: inherit;
      padding: 0 10px;
      text-decoration: none;
      cursor: pointer;
      &.sou {
        color: #fff;
        font-size: 18px;
      }
      &.en {
        span {
          display: block;
          margin-top: 18px;
          width: 24px;
          height: 24px;
          line-height: 22px;
          border-radius: 100%;
          border: 1px solid $gray;
          font-size: 12px;
          text-align: center;
          vertical-align: -1px;
        }
      }
    }
  }

  .nav-group {
    @include clearfix;
    .column {
      float: left;
      width: 50%;
      text-align: right;
      padding-right: 10%;
      & > li {
        display: inline-block;
        height: inherit;
        padding: 0 5%;
        &.li-first {
          display: none;
        }
        & > a {
          position: relative;
          display: block;
          height: inherit;
          line-height: inherit;
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          cursor: pointer;
          &.drop-down-btn {
            &:before {
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              background-color: $brand-primary;
              left: 50%;
              margin-left: -3px;
              top: 44px;
              transform: rotate(45deg);
              opacity: 0;
              transition: 0.3s;
            }
          }
        }

        &.active .drop-down-btn {
          &:before {
            opacity: 1 !important;
          }
        }
      }

      &.second {
        float: right;
        text-align: left;
        padding-left: 10%;
        padding-right: 0;
      }
    }

    .column li:nth-child(1),
    .column li:nth-child(2) {
      display: none;
    }

    .drop-down-box li:nth-child(1),
    .drop-down-box li:nth-child(2) {
      display: block;
    }

    // 改为左对齐顶部导航
    @media(min-width: $screen-md) {
      display: inline-block;

      .column,
      .column.second {
        display: inline-block;
        float: none;
        width: auto;
        padding:0;
        text-align: left;
        white-space: nowrap;
      }

      .column li {
        padding-left: 2em;
        padding-right: 2em;
      }

      .column.second li:nth-child(1),
      .column.second li:nth-child(2) {
        display: inline-block;
      }
    }

    @media (max-width: $screen-sm-max) {
      display: none;
      position: absolute;
      top: $nav-height;
      width: 45%;
      max-width: 170px;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      overflow: auto;
      .column {
        float: none;
        width: auto;
        text-align: left;
        padding: 0 !important;
        & > li {
          display: block;
          padding: 0;
          &.li-first {
            display: block;
          }

          & > a {
            line-height: 50px;
            padding: 0 12%;
            border-bottom: 1px solid $gray-darker;
            &.drop-down-btn:before {
              content: "\e688";
              font-family: icon;
              position: absolute;
              width: auto;
              height: auto;
              background: none;
              left: auto;
              right: 5%;
              margin-left: 0;
              top: 19px;
              line-height: initial;
              transform: none;
              opacity: 1;
              transition: 0.3s;
            }
            &.drop-down-none:before {
              content: none;
            }
          }
          &.active > a {
            color: $brand-primary;
            &.drop-down-btn {
              &:before {
                transform: rotate(180deg);
              }
            }
          }
        }

        &.second {
          float: none;
        }
      }

      .column.second li:nth-child(1),
      .column.second li:nth-child(2) {
        display: block;
      }
    }
  }

  .phone-btn {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    line-height: inherit;
    padding: 19px 17px;
    .menu {
      width: 26px;
      height: 22px;
      line-height: 22px;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #fff;
        top: 8px;
      }
    }
    &.open {
      .menu {
        border-color: transparent;
        top: 0;
        text-align: center;
        font-size: 34px;
        &:before {
          content: "×";
          height: auto;
          background: none;
          color: #fff;
          top: -4px;
        }
      }
    }
    @media (max-width: $screen-sm-max) {
      display: block;
    }
  }

  .drop-down-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $body-bg;
    border-bottom: 2px solid $brand-primary;
    padding: 5% 8% 0;
    display: none;
    height: rempc(500);

    .item-box {
      float: left;
      width: 15%;
      li {
        text-align: left;
        line-height: rempc(50);
        a {
          cursor: pointer;
          color: $text-color;
          text-decoration: none;
          display: block;
          &:hover {
            color: $brand-primary;
          }
        }
        &.concept {
          &.active > a {
            color: #d2b88a;
          }
          ul {
            display: none;
            margin-top: -5px;
            li {
              line-height: rempc(30);
            }
          }
        }
      }
    }
    .show-box {
      float: right;
      width: 85%;
      margin-top: rempc(16);
      .show {
        float: left;
        width: 33.33%;
        padding: 0 10px;
        cursor: pointer;
        text-decoration: none;
        color: #fff;
        &:hover {
          color: $brand-primary;
        }
        .name {
          margin-top: 10px;
          text-align: center;
          line-height: normal;
        }
      }
    }

    &.car-drop {
      // padding: 2% 12% 5%;
      padding: 0 4% 2%;
      height: auto;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .car-drop-swiper {
        @include clearfix;
        margin-top: 2%;

        .swiper-slide {
          padding: 0 1.5%;
          box-sizing: border-box;
          .img {
            display: block;
            width: 100%;
          }
          .info {
            overflow: hidden;
            color: #fff;
            line-height: 30px;
            height: 30px;
            .name {
              float: left;
              font-size: 56px;
            }
            .price {
              float: right;
              font-size: 13px;
            }
          }
          .des {
            text-align: left;
            color: $brand-primary;
            margin-top: 5px;
            .more {
              float: right;
              color: $text-color;
              text-decoration: underline;
              &:hover {
                color: $brand-primary;
              }
            }
          }
        }

        .car-content {
          padding: 0 2.5%;
          box-sizing: border-box;
          width: 25%;
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      position: static;
      border-bottom: 0;
      padding: 0;
      background-color: #292929;
      height: auto;
      .item-box {
        float: none;
        width: auto;
        li {
          border-bottom: 1px solid #434343;
          a {
            font-size: 14px;
            line-height: 40px;
            height: 40px;
            overflow: hidden;
            display: block;
            padding: 0 12% 0 18%;
            .icon {
              font-size: 48px;
            }
          }
          &.concept {
            & > a:hover {
              color: inherit;
            }
            ul {
              li {
                border: none;
                a {
                  font-size: 13px;
                  line-height: 30px;
                  height: 30px;
                  overflow: hidden;
                  display: block;
                  padding: 0 12% 0 18%;
                }
                &:last-of-type {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
      .show-box {
        display: none;
      }

      &.car-drop {
        display: none;
      }
    }
  }
}

// 2023-03-28 顶部导航改为Swiper
.navbar .car-drop-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* align-items: center; */
}

.navbar .car-details {
  width: 80%;
  margin: 0 auto;
}

.navbar .detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2;
  font-size: rempc(16);
}

.navbar .detail-row .car-logo {
  width: 60%;
}

.navbar .detail-row .car-logo img {
  width: 100%;
  height: auto;
}

.navbar .detail-row .car-price {
  white-space: nowrap;
  color: #eee;
  min-width: 8.5em;
  text-align: right;
}

.navbar .detail-row .car-slogan {
  color: #d2b88a;
  white-space: nowrap;
}

.navbar .car-content a {
  display: block;
}

.navbar .car-content a:hover {
  text-decoration: none;
}

.navbar .detail-row .car-link {
  color: #eee;
  white-space: nowrap;
}

.navbar .detail-row .car-link:hover {
  text-decoration: underline;
}

.swiper-button-prev:after {
  font-family: "icon" !important;
}

.swiper-button-next:after {
  font-family: "icon" !important;
}

@media (max-width: 1366px) {
  .navbar .car-content {
      padding: 0 !important;
  }

  .navbar .car-details {
      width: 85%;
  }

  .navbar .detail-row .car-logo {
      width: 50%;
  }
}

@media (max-width: 1024px) {
  .navbar .drop-down-box .item-box li a {
      padding: 0 15%;
  }

  .navbar .nav-car-img {
    height: 100%;
  }
}

.privacy-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: $nav-height;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 11;
  display: none;
  .container {
    padding: 14px 0 20px;
    position: relative;
  }
  p {
    display: inline-block;
    padding-bottom: 8px;
    width: 85%;
  }
  .close_cookies {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    i {
      font-size: 16px;
    }
  }
  .agree-btn {
    // float: right;
    width: 15%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    .btn {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      width: 70px;
      height: 33px;
      margin-top: -16.5px;
      // margin-left: -35px;

      &:first-child {
        color: #fff;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    p {
      width: 100%;
    }
    .agree-btn {
      float: none;
      position: relative;
      margin: 0;
      transform: none;
      width: 100%;
      .btn {
        position: relative;
        width: 70px;
        height: 33px;
        margin: 0;
        top: 0;
        left: 0;
        right: 0;
        float: right;
      }
    }
    .close_cookies {
      right: -13px;
    }
  }
}

.privacy-policy {
  display: inline-block;
  cursor: pointer;
  color: $brand-primary;
  &:hover {
    text-decoration: underline;
  }
}
