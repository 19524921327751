@import "../config";

// 三图布局
.colunm-thr{
  @include clearfix;position: relative;margin-top: 40px;
  .tran-pic{color: #fff;margin-right: 2%;}
  .txt{position: absolute;left: 5%;bottom: 5%;font-size: rempc(30);}
  .info{
    position: absolute;right: 5%;bottom: 6%;font-size: 12px;
    .cont{
      @include icon-line();
      i{margin: 0 6px;}
    }
    .date{color: $gray-light;margin-top: 8px;text-align: right;}
  }
  .second{
    position: absolute;right: 0;width: 50%;
    .tran-pic{margin-left: 2%;margin-right: 0;}
    &.second-first{top: 0;}
    &.second-last{bottom: 0;}
  }

  @media (max-width: $screen-sm-max) {
    .tran-pic{margin-right: 0;}
    .txt{font-size: rem(30);}
    .second{
      position: static;width: 100%;margin-top: 15px;
      .tran-pic{margin-left: 0;}
    }
  }
}

// 公共列表页
.list-box{
  margin: 40px auto 30px;
  &.dynamic-related-list { font-size: 0; }
  @include clearfix;
  @media (max-width: $screen-xs-max) {
    margin: 40px -20px 30px;
  }
  [class^='col-lg-']{float: none;display: inline-block;vertical-align: top;}
  .title{
    font-size: 24px;text-align: center;color: #fff;font-weight: bold;margin: 30px 0;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }

  // 有下标
  .sup-line{
    &:after{content: '';position: absolute;left: 5%;right: 5%;bottom: 0;border-bottom: 2px solid $gray-dark;transition: border-color .5s;}
    &:hover{
      &:after{border-color: $brand-primary;}
    }
  }

  // 动态页样式
  .dynamic-list{
    background-color: #151515;margin: 0 20px 40px;position: relative;
    @media (max-width: $screen-xs-max) {
    }
    &:hover{
      background:linear-gradient(180deg,rgba(34,34,34,1) 0%,rgba(21,21,21,1) 100%);
      .tran-pic{
        img{transform: scale(1.1, 1.1);}
        &:after{background-color: transparent;}
      }
    }

    // 角标
    .tip-icon{
      position: absolute;top: 0;left: 10%;width: 54px;padding: 12px 0;font-size: 12px;color: #fff;z-index: 11;line-height: 16px;text-align: center;
      &.report{background-color: $brand-danger;}
      &.news{background-color: $brand-primary;}
    }

    .cont-box{
      padding: 20px;
      .tit{font-size: 15px;color: $brand-primary;font-weight: bold;text-align: left;
        a{color: $gray-lighter;line-height: 24px;height: auto;
          &:hover{color: $brand-primary;}
        }
      }
      /*.des{margin-top: 20px;line-height: 22px;height: 44px;overflow: hidden;text-align: left;font-size: 13px;word-break: break-all;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;display: -moz-box;-moz-box-orient: vertical;-moz-line-clamp: 2;text-overflow: ellipsis;}*/
      .date{
        font-size: 12px;
        i{margin-right: 5px;}
      }
      .explain{
        @include clearfix;line-height: 20px;font-size: 12px;padding-top: 12px;margin-top: 12px;border-top: 1px solid $gray-darker;
        .date{float: left;}
        .name{
          float: right;@include icon-line();
        }
      }

      &.activity{
        .explain{
          border-top: 0;
          .date{line-height: 34px;}
          .btn{
            border-color: #979797;color: $brand-primary;
            &:hover{background-color: rgba(216,216,216,0.15);}
          }
        }
      }

      &.download{
        .tit{margin-bottom: 6px;}
        .date{color: $gray-dark;}
        .explain{
          .btn-down{
            color: $text-color;
            i{margin-right: 3px;}
            &:hover{color: $brand-primary;}
          }
          .name{
            i{display: inline-block;margin: 0 10px;width: 1px;height: 10px;background-color: $gray;vertical-align: -1px;}
            .icon{font-size: 15px;vertical-align: -1px;}
          }
        }
      }
    }
  }

  // 车型手册样式
  .note-list-col{
    @media (max-width: $screen-md-max) {
      display: block;
    }
  }
  .note-list{
    overflow: hidden;
    .tran-pic{width: 50%;float: left;}
    .note-cont{
      position: absolute;width: 50%;right: 0;text-align: center;top: 50%;transform: translateY(-50%);
      .tit{
        padding-top: 20px;padding-bottom: 20px;font-size: 24px;font-weight: bold;
        img{width: 60%;}
      }
      .btn{
        display: block;width: 50%;max-width: 150px;margin: 10px auto 0;background-color: $gray-darker;color: $text-color;
        .icon{color: $brand-primary;}
        &:hover{color: $brand-primary;}
      }
    }

    @media (max-width: $screen-xs-max) {
      .tran-pic{width: 100%;float: none;}
      .note-cont{
        position: relative;transform: none;width: 100%;padding-bottom: 30px;
        .tit{ img{max-width: 300px;} }
      }
    }
  }

  .load-more{
    text-align: center;
    .btn{
      color: $gray-lighter;text-align: center;animation: ani-rotate 2s linear infinite;
    }
  }

  .note-explain{
    margin-top: 6%;line-height: 24px;padding: 0 20px;
  }

  @media(max-width: $screen-xs-max){
    margin-left: -15px;margin-right: -15px;
  }
}

@keyframes ani-rotate {
  100%{
    transform:rotate(360deg);
  }
}