// 自定义sass库
@import "config";

// 插件类
@import 'bootstrap-sass/bootstrap';
@import "../../lib/swiper/2.x/idangerous.swiper.css";
@import '../../lib/bootstrap-select/sass/bootstrap-select';
@import '../../lib/animate.css/animate.css';

// 自定义组件
@import "plugs/navbar";
@import "plugs/footer";
@import "plugs/list";

//ie解决导航栏覆盖内容问题
html,
body {
  -ms-overflow-style: scrollbar;
}

//ng-cloak来实现防止闪烁的方案
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide {
  display: none !important;
}

ng\:form {
  display: block;
}

//没有数据
.none-data {
  text-align: center;
  padding: 80px 0;
  font-size: 16px;
}

// 样式重置
body {

  // 重置layer部分样式
  .layui-layer {
    .layui-layer-title {
      background-color: $gray-darker;
      color: #fff;
      border-bottom-color: $gray-dark;
    }

    .layui-layer-content {
      background-color: $gray-darker;
    }

    .layui-layer-btn {
      background-color: $gray-darker;

      .layui-layer-btn0 {
        border: $brand-primary;
        background-color: $brand-primary;
      }
    }
  }

  // layer弹出层拓展
  .layui-layer-pop {
    background-color: $gray-darker;

    .layui-layer-title {
      font-size: 18px;
      font-weight: bold;
      padding: 20px 10%;
      height: auto;
    }

    .layui-layer-content {
      padding: 0 15px 0 0;
      margin: 20px 10%;
      @include scrollbar;

      h4 {
        font-size: 15px;
        margin-top: 10px;
        font-weight: bold;
      }

      p {
        font-size: 13px;
        margin-top: 6px;
      }
    }

    .layui-layer-btn {
      text-align: center;
      padding: 20px 0;
      border-top: 1px solid $gray-dark;

      .layui-layer-btn0 {
        background: none;
        margin: 0;
        color: $gray-dark;
        padding: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 30px;
        font-family: 'icon';

        &:before {
          content: '\e61e';
        }
      }
    }
  }

  // 字体图标默认样式
  .icon {
    font-size: inherit;
    display: inline-block;
    fill: currentColor;
  }

  .iconfont {
    font-size: inherit;
    display: inline-block;
  }
}

// 没有顶部距离
.none-mtop {
  margin-top: 0 !important;
}

.none-pbottom {
  padding-bottom: 0 !important;
}

// 没有边框
.none-border {
  border: 0 !important;
}

.position-relative {
  position: relative;
}

// 轮播默认按钮样式
[class^="swiper-button"] {
  position: absolute;
  font-size: rempc(48);
  z-index: 1;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  opacity: .6;
  transition: opacity .5s;

  &:hover {
    opacity: 1;
  }

  &:after {
    font-family: 'icon';
    color: $gray-lighter;
  }

  &.swiper-button-prev {
    left: 5px;

    &:after {
      content: '\e689';
    }
  }

  &.swiper-button-next {
    right: 5px;

    &:after {
      content: '\e681';
    }
  }
}

h1 {
  text-align: center;
  color: #fff;
  font-size: 24px;
  padding: 36px 0 24px;

  @media (max-width: $screen-xs-max) {
    font-size: 17px;
  }
}

.pagination {
  left: 0;
}

// 轮播插件
.kv-swiper {
  width: 100%;

  .swiper-container {
    width: 100%;
  }

  .pagination {
    bottom: 5%;
    margin: 0;

    .swiper-pagination-switch {
      position: relative;
      height: 3px;
      width: 6%;
      border-radius: 0;
      background-color: rgba(255, 255, 255, 0.45);
      opacity: 1;
      margin: 0 1%;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        background-color: #fff;
      }

      &.swiper-active-switch:before {
        width: 100%;
        transition: 5.3s linear;
      }
    }
  }

  .swiper-slide {
    display: block;

    img {
      width: 100%;
    }

    .hidden-xs {
      position: relative;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }

      .icon-play {
        position: absolute;
        font-size: 20px;
        z-index: 1
      }
    }

    .txt-box {
      position: absolute;
      left: 7%;
      top: 17%;
      color: #fff;
      width: 30%;

      .tit {
        font-size: 24px;
        font-weight: bold;
        margin-top: 5px;
      }

      .des {
        margin-top: 5px;
        letter-spacing: 1px;
      }

      .read-more {
        margin-top: 30px;
        overflow: hidden;

        .btn {
          display: inline-block;
          background-color: rgba(22, 22, 22, 0.3);
          color: #FFFFFF;
          border-color: rgba(255, 255, 255, 1);
          padding: 12px 50px;

          &:hover {
            background: $link-hover-color;
            border-color: $link-hover-color;
          }
        }
      }

      @media (max-width: $screen-xs-max) {
        width: 88%;
        left: 6%;
        top: 10%;
        text-shadow: 1px 1px 3px $gray;

        .des {
          margin-top: 15px;
          letter-spacing: 1px;
        }
      }
    }
  }
}


.column-swiper-wrap {
  position: relative;
  margin-top: 6%;

  .avatar {
    width: 100%;
    vertical-align: middle;
  }

  .column-swiper {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .swiper-wrapper {
      opacity: 0;
      transition: 1s;
    }

    &:hover .swiper-wrapper {
      opacity: 1;
    }

    .swiper-pagination {
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;

      .swiper-pagination-item {
        position: relative;
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.2);

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 4px;
          background: $brand-primary;
          transition: 1s;
        }

        &:hover {
          background: transparent;

          &:before {
            width: 100%;
          }

          .box {
            transform: translateY(-90%);
            transition: 1.2s;

            h3 {
              font-size: 22px;
            }

            &.vertical-bottom {
              transform: translateY(-50px);
            }

            p {
              opacity: 1;
              transform: translateY(0);
              transition: 1.2s .5s;
            }
          }
        }

        .box {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          transition: 1.2s;

          h3 {
            font-size: 18px;
            color: #fff;
          }

          p {
            font-size: 12px;
            margin-top: 12px;
            line-height: 1.5;
            opacity: 0;
            transform: translateY(30px);
            transition: 0s;
          }


          &.vertical-bottom {
            top: auto;
            transform: none;
            bottom: 6%;
          }
        }
      }
    }

    [class^="swiper-button"] {
      &:after {
        font-family: 'icon';
        color: $gray-lighter;
      }

      &.swiper-button-prev:after {
        content: '\e689';
      }

      &.swiper-button-next:after {
        content: '\e681';
      }
    }

    .swiper-slide {
      .txt-box {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 12%;
        text-align: center;
        font-size: 12px;
        color: #fff;

        .tit {
          font-size: 18px;
          font-weight: bold;
          margin-top: 5px;
        }

        .des {
          margin-top: 5px;
        }
      }
    }
  }

  .txt-box {
    width: 88%;
    margin: 4% auto 0;

    .txt {
      display: none;

      p {
        margin-top: 20px;
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }

}




// 图文栏目
.column-img-txt {
  @include clearfix;
  margin-top: 6%;
  position: relative;

  &.pd-top-btm {
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .img {
    float: left;
    width: 46%;
  }

  .txt {
    //@include title-arrow-down(20px, 1px);
    position: absolute;
    left: 54%;
    top: 50%;
    transform: translateY(-50%);
    width: 46%;

    h3 {
      line-height: 1.5;
      margin-top: 20px;
    }

    h4 {
      margin-top: 10px;
      color: $gray-light;
      line-height: 1.5;

      &.yellow-h4 {
        color: #d2b88a;
      }
    }

    p {
      line-height: 26px;
      margin-top: 30px;
      color: $gray;
    }

    &.none-before:before {
      display: none;
    }
  }

  &.reverse {
    .img {
      float: right;
    }

    .txt {
      left: 0;
    }
  }

  &.column-txt {
    .img {
      float: none;
      width: 100%;
    }

    .txt {
      width: 100%;
      position: static;
      transform: translateY(0);
      margin-top: 20px;

      &.txt-marleft {
        margin-left: 0;
        width: 100%;
      }
    }

    @media (max-width: $screen-md-max) {
      .txt {
        padding: 0 15px;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .img {
      float: none !important;
      width: 100%;
    }

    .txt {
      position: static;
      width: 100%;
      transform: none;
      margin-top: 6%;

      &.none-arrow-down-md:before {
        display: none;
      }
    }
  }
}

// 图文栏目 - 平铺模式
.pic-and-txt {
  @include clearfix;
  margin-top: 6%;
  position: relative;

  .img {
    width: 100%;
  }

  .txt {
    //@include title-arrow-down(20px, 1px, true);
    width: 66%;
    margin: 3% auto 0;

    h3 {
      line-height: 1.5;
      font-weight: normal;
    }

    h4 {
      color: #E0E0E0;
      margin-top: 10px;
    }

    .subtitle {
      color: #fff;
      font-size: 16px;
      margin-top: 15px;
    }

    p {
      line-height: 30px;
      margin-top: 25px;
    }

    .icon-plus {
      width: 30px;
      height: 30px;
      border: 1px solid #fff;
      border-radius: 50%;
      transform: rotate(45deg);
      transition: .3s;
      display: inline-block;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &.collapsed {
        transform: rotate(0deg);
      }
    }

    @media (min-width: $screen-sm-min) {
      &.arrow-down-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 46%;
      }

      &.insert {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 65%;
        margin-top: 0;
      }

      &.insert-left {
        width: 35%;
        position: absolute;
        left: 7%;
        top: 33%;
        margin-top: 0;
      }

      &.insert-right {
        width: 35%;
        position: absolute;
        right: 10%;
        top: 33%;
        margin-top: 0;

        &.img-xs-small {
          width: 80%;
        }
      }

      &.txt-w60 {
        width: 60%;
      }

      &.txt-w50 {
        width: 50%;
      }
    }

    @media (max-width: $screen-xs-max) {
      width: 80%;
      margin-top: 6%;
      margin-bottom: 6%;

      .img-xs-small {
        width: 40%;
      }

      p {
        margin-top: 20px;
      }
    }
  }

  .txt-absolute {
    position: absolute;
    left: 0;
    bottom: 16%;
    text-align: center;
    width: 100%;
    line-height: 30px;

    .icon {
      background: $brand-primary;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      margin-right: 8px;
      text-decoration: none;
      color: $gray-base;
      text-align: center;
    }
  }

  .box {
    padding: 0 6%;
    text-align: center;

    [class*="col-"] {
      padding: 0 3.7%;
    }

    h3 {
      line-height: 1.5;
    }

    h4 {
      color: #E0E0E0;
      margin-top: 4px;
      font-size: 14px;
    }

    p {
      line-height: 20px;
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

// 按钮tabs
.btn-tabs {
  width: 40%;
  margin: 40px auto 0;

  &.slow-mtop {
    margin-top: 10px;
  }

  .btn {

    &,
    &:focus {
      background-color: $gray-darker;
      color: $gray;
    }

    &:hover {
      color: $gray-lighter;
    }

    &.active {
      font-weight: bold;
      background-color: $brand-primary;
      color: #fff;
    }
  }

  @media (max-width: $screen-xs-max) {
    width: 90%;
  }
}

// 全屏视频播放
.full-screen-video {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .9);

  .video-box {
    position: absolute;
    left: 0;
    top: 5%;

    .swiper-wrapper {
      height: 100%;
    }

    .swiper-slide {
      text-align: center;

      video {
        display: inline-block;
        vertical-align: middle;
        max-height: 100%;
        max-width: 100%;
        outline: none;
      }
    }
  }

  [class^='arrow-'] {
    position: absolute;
    line-height: 50px;
    font-size: 32px;
    top: 50%;
    margin-top: -25px;
    color: #fff;
    cursor: pointer;
    opacity: .7;
    transition: .3s;

    &:hover {
      opacity: 1;
    }

    &.arrow-left {
      left: 15px;
    }

    &.arrow-right {
      right: 15px;
    }
  }

  .video-close {
    position: absolute;
    font-size: 30px;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    left: 50%;
    top: 92%;
    margin-left: -20px;
    text-align: center;
    cursor: pointer;
    opacity: .5;
    transition: .3s;

    &:hover {
      opacity: 1;
    }
  }
}

// 全屏大图浏览
.full-screen-pic {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $body-bg;

  .title {
    background-color: $gray-base;
    padding: 0 2.5%;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
  }

  .pic-close {
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 22px;
    color: #fff;
    right: 2.5%;
    top: 10px;
    text-align: center;
    cursor: pointer;
    opacity: .5;
    transition: .3s;

    &:hover {
      opacity: 1;
    }
  }

  .main-swiper {
    text-align: center;
    margin-top: 20px;

    .swiper-slide {
      .img {
        height: 100%;
        display: inline-block;
      }

      img {
        height: 100%;
      }
    }
  }

  .ctrl-box-bg {
    background-color: $gray-base;
    height: 100px;
    padding: 20px 0;
    margin-top: 20px;
    position: relative;
  }

  .ctrl-box {
    height: 60px;
    width: 500px;
    margin: 0 auto;
    position: relative;

    .ctrl-swiper {
      .swiper-slide {
        width: auto;
        cursor: pointer;

        .img {
          background: center / cover no-repeat;
          height: 100%;
          margin: 0 5px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, .6);
            transition: .3s;
          }
        }

        &.active {
          .img:before {
            background: none;
          }
        }
      }
    }

    [class^='arrow-'] {
      position: absolute;
      top: 15px;
      height: 30px;
      line-height: 30px;
      font-size: 22px;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      opacity: .7;
      transition: .3s;

      &:hover {
        opacity: 1;
      }

      &.arrow-left {
        left: -30px;
      }

      &.arrow-right {
        right: -30px;
      }
    }
  }

  .swiper-info {
    margin: 10px auto 0;
    padding: 10px 0;
    height: 40px;
    line-height: 20px;
    border-bottom: 1px solid $gray-darker;
    box-sizing: border-box;
    display: none;

    .num-box {
      span {
        color: $gray;
      }
    }

    .down-box {
      font-size: 12px;

      a {
        cursor: pointer;
        color: #fff;

        &:hover {
          color: $brand-primary;
        }
      }

      .all {
        margin-left: 20px;
      }

      i {
        vertical-align: 1px;
        margin-right: 5px;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .main-swiper {
      .swiper-slide {
        img {
          height: auto;
        }
      }
    }

    .swiper-info {
      padding-left: 15px;
      padding-right: 15px;
      width: 100% !important;
    }
  }

  @media (max-width: $screen-xs) {
    .ctrl-box {
      width: 80%;
    }
  }
}

// 提交弹层页面
.page-pop {
  position: relative;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 1;

  &.page-pop-absolute {
    position: absolute;
    width: 100%;
    height: 100%;

    .img {
      height: 100%;
      width: auto;
      max-width: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .img {
    width: 100%;
  }

  .box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;

    h3 {
      color: #fff;
    }

    p {
      margin-top: 15px;
      line-height: 25px;

      a {
        margin: 0 4px;
      }
    }

    .btn {
      background: #D2B88A;
      padding-top: 3px;
      padding-bottom: 3px;
      margin-top: 20px;
      width: 120px;
      color: $gray-base;
    }
  }
}

// 页面loading
.load-more {
  text-align: center;
  margin-top: 2%;

  .btn {
    color: $gray-lighter;
    text-align: center;
    animation: ani-rotate 2s linear infinite;
  }
}

@keyframes ani-rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* 次页导航信息 */
.sechead {
  height: rem(110);
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 rem(20);
  justify-content: space-between;

  .sechead_logoa {
    display: flex;
  }

  .sechead_logo {
    width: rem(125);
    height: rem(30);
  }

  .sechead_right {
    span {
      color: #1C2A55;
      vertical-align: bottom;
    }

    font-size: rem(26);
  }
}

body {
  transform: scale(1, 1) !important;
}

/* 移动端预约试驾模块 style */
.test_driver {
  // height: rem(1466);
  overflow: hidden;
  background: rgba(#D3D3D4, 0.2);

  .test_topcont {
    width: rem(342);
    height: rem(160);
    margin: 0 auto;
    margin-top: rem(150);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .tests_title {
      color: #50555B;
      font-size: rem(48);
      text-align: center;
      letter-spacing: rem(8);
      padding-left: rem(8);
    }

    .tests_cint {
      color: #50555B;
      font-size: rem(24);
      text-align: center;
      margin-top: rem(20);
      letter-spacing: rem(2);
    }

    .testdImg {
      width: rem(670);
      height: rem(386);
    }
  }

  .forms_item_inpt {
    width: rem(668);
    height: rem(116);
    line-height: rem(116);
    margin: 0 auto;
    border-bottom: rem(2) solid #D3D3D4;
    position: relative;

    input {
      border: none;
      width: 100%;
      height: 100%;
      background: transparent;
      font-size: rem(26);
      color: #000;
    }

    input:focus {
      outline: none;
      border: none;
      /* 如果需要，也可以移除边框 */
      box-shadow: none;
      /* 移除可能由浏览器添加的阴影效果 */
    }
  }

  .forms_item {
    width: rem(668);
    height: rem(116);
    line-height: rem(116);
    margin: 0 auto;
    border-bottom: rem(2) solid #D3D3D4;
    position: relative;

    .form_label {
      color: #000;
      font-size: rem(26);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: rem(500);
      display: block;

      &.disabled {
        color: #C0C4CC
      }
    }

    .rght {
      position: absolute;
      right: 0;
      top: rem(58);
      width: rem(32);
      height: rem(32);
    }
  }

  .Privacy {
    color: #50555B;
    font-size: rem(22);
    line-height: rem(22);
    display: flex;
    margin-top: rem(66);
    float: right;
    align-items: center;
    margin-right: rem(42);

    .unselected {
      width: rem(26);
      height: rem(26);
      margin-right: rem(14)
    }
  }

  .select_agent_box,
  .select_car_box {
    width: 100%;
    height: fit-content;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 98;
    overflow-x: hidden;

    .ageng_head {
      width: 100%;
      height: rem(80);
      border-bottom: rem(3) solid #D3D3D4;
      display: flex;
      box-sizing: border-box;

      .provice {
        width: 25%;
        height: rem(80);
      }

      .city {
        width: 25%;
        box-sizing: border-box;
        height: rem(80);
      }

      .agent_store {
        width: 50%;
        height: rem(80);
      }

      .common_text {
        font-size: rem(20);
        color: #000000;
        line-height: rem(80);
        text-align: center;
        cursor: pointer;
        position: relative;
      }

      .common_provice {
        box-sizing: border-box;
        padding: 0 rem(24);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .current_line {
        width: 100%;
        position: absolute;
        height: rem(2);
        background: #1C2A55;
        bottom: 0;
      }
    }

    .agent_content {
      width: 100%;
      height: rem(420);
      overflow-x: hidden;
      overflow-y: auto;
      padding: rem(15) 0;
      box-sizing: border-box;

      .provice_city_agent {
        width: 100%;
        height: rem(60);
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 rem(24);

        .left_provice_city_agent {
          width: 85%;
          height: rem(60);
          line-height: rem(60);
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: rgba(80, 85, 91, 0.3);
          font-size: rem(20);
          // padding-left: rem(24);
        }

        .right_provice_city_agent {
          width: 15%;
          height: rem(60);
          line-height: rem(60);
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            color: #1C2A55;
            font-size: rem(24);
            font-weight: bold;
          }
        }
      }

      .active_bottom {
        border-bottom: rem(3) solid #1C2A55;
      }

      .active_text_wen {
        color: #1C2A55 !important;
      }
    }
  }

  .test_driver_submit {
    width: rem(670);
    height: rem(96);
    text-align: center;
    font-size: rem(28);
    color: #fff;
    line-height: rem(96);
    border-radius: rem(6);
    margin: 0 auto;
    margin-top: rem(160);
    background: #1C2A55;
    margin-bottom: rem(40);
  }

  .a-content {
    height: 90vh;
    overflow: auto;
    padding: rem(30);
    font-weight: 300;
    font-size: rem(24);
    color: #50555B;
    line-height: rem(40);
    letter-spacing: rem(4);
    font-style: normal;
    text-transform: none;

    .agreementTitle {
      font-weight: bold;
    }

    .text_agree_title {
      font-size: rem(40);
      line-height: rem(50);
      letter-spacing: rem(8);
      font-weight: bold;
      margin-bottom: rem(20);
    }
  }

  #orderSuccess1,
  #yinsi1 {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #F6F6F6;
    display: flex;
    flex-flow: column;
    align-items: center;

    .close_order_success_modal {
      box-sizing: border-box;
      cursor: pointer;
      font-size: rem(60);
      width: 100%;
      text-align: right;
      color: #1C2A55;
    }

    .order_success_title {
      font-weight: 300;
      font-size: rem(48);
      color: #50555B;
      line-height: rem(56);
      letter-spacing: rem(8);
      text-align: center;
      font-style: normal;
      text-transform: none;
    }

    .order_success_content {
      width: rem(600);
      font-weight: 300;
      font-size: rem(24);
      color: #50555B;
      line-height: rem(40);
      letter-spacing: rem(4);
      text-align: center;
      font-style: normal;
      text-transform: none;
    }

    img {
      width: rem(222);
      height: rem(222);
    }

    .liji_saoma {
      font-weight: 300;
      font-size: rem(24);
      color: #50555B;
      line-height: rem(28);
      letter-spacing: rem(4);
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }
}

.config-info {
  margin-top: rem(280);

  .test_topcont {
    width: rem(342);
    height: rem(160);
    margin: 0 auto;
    margin-top: rem(80);
    margin-bottom: rem(-60);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .tests_title {
      color: #50555B;
      font-size: rem(48);
      text-align: center;
    }

    .tests_cint {
      color: #50555B;
      font-size: rem(24);
      text-align: center;
      margin-top: rem(20);
      letter-spacing: rem(2);
    }

    .testdImg {
      width: rem(670);
      height: rem(386);
    }
  }

  .forms_item {
    width: rem(668);
    height: rem(116);
    line-height: rem(116);
    margin: 0 auto;
    border-bottom: rem(2) solid #D3D3D4;
    position: relative;

    select {
      position: absolute;
      top: rem(47);
      left: 0;
      z-index: 2;
      color: #000;
      font-size: rem(26);
      width: 100%;
      height: rem(50);
      line-height: rem(50);
      border: none;
      // margin-top: rem(20);
      background-color: transparent;
      &:focus-visible {
        outline: none;
      }
    }

    .rght {
      position: absolute;
      z-index: 1;
      right: 0;
      top: rem(58);
      width: rem(32);
      height: rem(32);
    }
  }

  .carName {
    font-weight: 300;
    font-size: rem(30);
    color: #FFFFFF;
    line-height: rem(82);
    text-align: left;
    font-style: normal;
    text-transform: none;
    height: rem(82);
    background: #1C2A55;
    border-radius: 0px 0px 0px 0px;
    border: rem(2) solid #D3D3D4;
    text-align: center;
  }

  .cell_point {
    border: rem(2) solid #D3D3D4 !important;
    line-height: rem(44);
    padding: rem(15);
  }

  table {
    width: 100%;
    font-weight: 300;
    font-size: rem(24);
    color: #1C2A55;
    line-height: rem(28);
    text-align: left;
    font-style: normal;
    text-transform: none;

    td {
      border: rem(2) solid #D3D3D4 !important;
      line-height: rem(44);
      padding: rem(15);
    }

    .td-value {
      max-width: rem(335);
      width: rem(335);
      padding: rem(0);

      div {
        line-height: rem(44);
        padding: rem(15);
        width: rem(335);
      }
    }
  }

  .config-box {
    width: rem(668);
    margin: rem(54) auto rem(54);
    font-weight: 300;
    font-size: rem(24);
    color: #1C2A55;
    text-align: center;
    font-style: normal;
    text-transform: none;
    min-height: 30vh;

    .zccs {
      height: rem(74);
      line-height: rem(74);
    }

    // .td-value {
    //   max-width: rem(200);
    //   min-width: rem(200);
    //   width: rem(200);
    // }


    .border {
      border: rem(2) solid #D3D3D4;
      height: rem(74);
      line-height: rem(74);
    }

    .zccs {
      font-size: rem(30);
      line-height: rem(82);
      text-align: left;
      font-style: normal;
      text-transform: none;
      height: rem(82);
      background: #D3D3D4;
      border-radius: 0px 0px 0px 0px;
      border: rem(2) solid #D3D3D4;
      text-align: center;
      font-weight: 600;
      color: #1C2A55;
      text-align: left;
      font-style: normal;
      text-transform: none;
      text-align: center;
    }
  }
}

.web_config_remark {
  width: 100%;
  border: rem(2) solid #D3D3D4;
  text-align: left;
  // white-space: pre-wrap;
  border-top: none;
  background-color: #D3D3D4;
  padding: rem(15) rem(20);
  font-size: rem(14);
}
