@import "../config";

.footer{
  background-color: $gray-base;position: relative;
  .footer-main{
    .footer-middle{
      margin: 80px 0 50px;
      .footer-nav{
        width: 60%;
        display: flex;
        justify-content: start;

        .column-list{
          margin-bottom: 0;
          margin-right: 10%;
          dt{
            color: #fff;font-size: 15px;line-height: 25px;font-weight: normal;
            span{display: none;}
          }
          dd{
            margin-top: rempc(10);
            p{margin-bottom: 0;}
            a{
              color: #B8B8B8;font-size: 12px;line-height: 24px;
              &:hover{color: $brand-primary;}
            }
            .concept-list {display: none;}
            &.last-column{
              line-height: 24px;
              .chinese-txt{vertical-align: middle;}
              .chinese-icon{margin-left: 10px;font-size: 26px;vertical-align: middle;color: #DE2910;background-color: #FFDE00;}
            }
          }
        }

        dl:nth-child(1),
        dl:nth-child(2) {
          display: none;
        }
      }
      .footer-qrcode{
        width: 18%;font-size: 50px;
        .img-box{
          width: 100%;display: flex;justify-content: space-between;
          li{
            cursor: pointer;
            .icon{font-size: 20px;width: 40px;height: 40px;line-height: 40px;border-radius: 50%;background-color: #B8B8B8;color: #000000;text-align: center;}
            a:hover{text-decoration: none;}
          }
          @media (max-width: $screen-sm-max){
            width: 80%;margin: 0 auto;
          }
        }
      }
    }
    .footer-infos{
      display: flex;justify-content: space-between;font-size: 12px;color: #fff;border-top: 1px solid rgba(255,255,255,0.15);padding: 8px 0;text-align: left;align-items: center;
      .bot-infos-right{
        .hongqi{line-height: 13px;
          .flagOfChina {height: 13px;width: auto;vertical-align: text-bottom;margin-right: 5px}}
        a{color: #ffffff;margin: 0 5px;}
      }
    }
  }

  @media (max-width: $screen-sm-max){
    .footer-main{
      .footer-middle{
        margin-top: 40px;
        .footer-nav{
          width: 100%;display: block;
          .column-list{
            width: 100%;
            dt{
              border-bottom: 1px solid $gray-darker;padding: 8px 0;position: relative;
              span{
                display: block;position: absolute;left: 0;right: 0;top: 0;bottom: 0;
                i{float: right;line-height: 40px;transition: .5s;}
              }
            }
            dd{display: none;padding: 10px 0;}

            &.open{
              dt{
                i{transform: rotate(180deg);}
              }
            }
          }
        }
        .footer-qrcode{width: 100%;margin-top: 40px;}
      }
      .footer-infos{
        text-align: center;display: block;
        .bot-infos-right{margin-top: 10px;}
      }
    }
  }

.minor-part{position: fixed;right: 10px;bottom: 100px;z-index: 9;
  .name{
    cursor: pointer;text-decoration: none;display: block;width: 32px;height: 32px;line-height: 32px;background-color: #000;border-radius: 100%;margin-top: 10px;text-align: center;
    i{margin: 0 6px;display: inline-block;
      &.icon-big{font-size: 16px;color: $brand-primary;vertical-align: -1px;}
      &.icon-arrow-down{transition: .3s;}
    }
  }
.footbar-box{display: block;position: fixed;right: 0;bottom: 20%;width: 40px;z-index: 10;background-color: rgba(0,0,0,.7);
  a{color: #fff;display: block;height: 40px;text-decoration: none;font-size: 12px;line-height: 40px;position: relative;text-align: center;
    i{font-size: 24px;}
    /*span{position: absolute;left: 100%;top: 0;width: 100%;height: 100%;-webkit-transition: .3s;transition: .3s;line-height: 14px;padding: 13px 0}
    &:hover{
      span{left: 0;background-color: #d2b88a;color: #000;}
    }*/}}}
}
